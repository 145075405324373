<template>
  <div>
    <b-card v-if="!imagesLoading">
      <b-card-text>
        <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="tableSettings.searchTerm"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
        <vue-good-table
          :columns="tableSettings.columns"
          :rows="images"
          :search-options="{
            enabled: true,
            externalQuery: tableSettings.searchTerm 
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: false,
          }"
          class="table-valign-middle"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'preview'"
              class="text-nowrap"
            >
              <img :src='props.row.thumbnail' class='image-preview'/>
            </span>

            <span
              v-else-if="props.column.field === 'title.rendered'"
              class="text-nowrap" v-html="props.row.title.rendered"
            >
            </span>

            <span
              v-else-if="props.column.field === 'meta.title'"
              class="text-nowrap inline-edit-field"
              style="min-width: 300px;"
            >
            
              <form @submit.prevent="updateTitle(props.row, props.row.meta.title)">
                <input type="text" v-model='props.row.meta.title' :disabled="!props.row.title_input_active"/>
              </form>

              <button @click="props.row.title_input_active = true" v-if="props.row.title_input_active == false">
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </button>
              <button @click="updateTitle(props.row, props.row.meta.title)" v-else>
                <feather-icon
                  icon="SaveIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </button>
            </span>

            <span
              v-else-if="props.column.field === 'tags'"
              class="text-nowrap"
            >
              <b-badge v-for="tag in props.row.tags" :key="tag" variant="primary" pill>{{tag}}</b-badge> 
            </span>

            <span v-else-if="props.column.field === 'actions'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item :to="'/products/manage/' + props.row.id">
                  
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDelete(props.row)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>
        </vue-good-table>  

        <pagination :pagination="pagination"
                    @prev="--postsData.page; getImages();"
                    @next="postsData.page++; getImages();">
        </pagination>
      </b-card-text>
    </b-card>

    <div class="d-flex justify-content-center mb-3">
      <b-spinner variant="primary" label="Loading" v-show="imagesLoading"></b-spinner>
    </div>

    <b-modal
      ref="confirm-delete"
      ok-variant="danger"
      ok-title="Verwijderen"
      cancel-title="Annuleren"
      cancel-variant="outline-secondary"
      modal-class="modal-danger"
      centered
      title="Weet je het zeker?"
    >
      <b-card-text>
        Weet je zeker dat je dit product wilt verwijderen? Deze zal niet meer verkocht kunnen worden, klanten die het product reeds aangeschafd hebben kunnen deze nog steeds downloaden.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BFormGroup, BFormInput, BDropdown, BDropdownItem, BBadge, BSpinner, BModal, VBModal, BButton, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/api'
import Pagination from '@/layouts/components/Pagination.vue'

import '@/@core/scss/vue/libs/vue-good-table.scss'

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardText,
    BFormGroup, 
    BFormInput,
    BDropdown,
    BDropdownItem,
    BBadge,
    BSpinner,
    BModal, 
    VBModal,
    BButton,
    Pagination,
  },
  
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  data() {
    return {
      images: [],
      imagesLoading: true,
      productToDelete: null,
      tableSettings: {
        searchTerm: '',
        columns: [
          {
            label: '#',
            field: 'id',
          },
          {
            label: 'Preview',
            field: 'preview',
          },
          {
            label: 'Product title',
            field: 'meta.title',
          },
          {
            label: 'Album',
            field: 'album',
          },
          {
            label: 'Tags',
            field: 'tags',
          },
          {
            label: 'Actions',
            field: 'actions',
          },
          {
            label: 'Filename',
            field: 'title.rendered',
          },
        ],
        rows: [],
      },
      postsData: {
          per_page: 100,
          page: 1
      },
      pagination: {
          prevPage: '',
          nextPage: '',
          totalPages: '',
          from: '',
          to: '',
          total: '',
      },
    }
  },

  mounted() {
    this.getImages()
  },

  methods: {
    getImages() {
      api.images.all(this.postsData.per_page, this.postsData.page).then(response => {
        this.images = response.data

        for (var i = 0; i < this.images.length; i++) {
          this.images[i].title_input_active = false;
        }

        this.configPagination(response.headers);

        this.imagesLoading = false
      })
    },

    updateTitle(product, title) {
      product.title_input_active = false;

      api.images.update(product.id, {
        meta: {
          title,
        }
      }).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Title updated`,
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })
      })
    },

    confirmDelete(product) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this product?', {
          title: `Delete product ${product.id}?`,
          okVariant: 'primary',
          okTitle: 'Delete',
          okVariant: 'danger',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(response => {
          if (response === true) {
            api.images.delete(product.id).then(response => {
              console.log(response)
              this.getImages()
            })
          }
        })
    },

    configPagination(headers) {
      this.pagination.total = +headers['x-wp-total'];
      this.pagination.totalPages = +headers['x-wp-totalpages'];
      this.pagination.from = this.pagination.total ? ((this.postsData.page - 1) * this.postsData.per_page) + 1 : ' ';
      this.pagination.to = (this.postsData.page * this.postsData.per_page) > this.pagination.total ? this.pagination.total : this.postsData.page * this.postsData.per_page;
      this.pagination.prevPage = this.postsData.page > 1 ? this.postsData.page : '';
      this.pagination.nextPage = this.postsData.page < this.pagination.totalPages ? this.postsData.page + 1 : '';
    },
  }
}
</script>

<style>
  .image-preview {
    max-width: 40px;
    transition: .2s ease max-width;
  }

  .image-preview:hover {
    max-width: 100px;
  }

  table.vgt-table td {
    vertical-align: middle;
  }

  .badge {
    margin: 2px;
    font-weight: lighter;
  }

  .inline-edit-field {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-column-gap: 5px;
  }

  .inline-edit-field input {
    width: 100%;
    background: transparent;
    border: 1px solid #dbdbdb;
  }

  .inline-edit-field input:disabled {
    border: 1px solid transparent;
  }

  .inline-edit-field button {
    background: transparent;
    border: none;
  }
</style>
