<template>
    <nav class="ps-pagination pt-1">
        <div style="display: block;">
            <p class="page-stats">Showing images {{pagination.from}} - {{pagination.to}} of {{pagination.total}}</p>
        </div>

        <div style="display: block;">
            <button :disabled="!pagination.prevPage" @click="$emit('prev')" class="btn btn-primary btn-sm pagination-previous">
                Prev page
            </button> &nbsp;

            <button :disabled="!pagination.nextPage" @click="$emit('next')" class="btn btn-primary btn-sm pagination-next">
                Next page
            </button>
        </div>
    </nav>
</template>

<script>
    export default {
        props: ['pagination'],
    }
</script>